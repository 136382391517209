//
// slimscroll.scss
//

.slimScrollDiv {
  height: auto !important;
}

.ps__rail-x {
  display: none !important;
}

.ps__thumb-y {
  width: 4px !important;
}
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  width: 4px !important;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: transparent !important;
}
