//
// nav.scss
//

// Bootstrap override - turn nav tab with broder

.nav-tabs {
  border-bottom: 2px solid rgba($gray-600, 0.2);

  .nav-item {
    margin-bottom: -2px;
  }

  li {
    a {
      padding: 0.625rem 1.25rem;
      border-color: transparent !important;
    }
    a.active {
      border-bottom: 2px solid $primary !important;
    }
  }
}

// pills
.nav-tabs,
.nav-pills {
  > li {
    > a {
      font-weight: $font-weight-bold;
      color: $gray-700;
    }
  }
}

.nav-pills {
  padding: 6px 2px;
  background: $body-bg;
  border-radius: $border-radius;

  .nav-link {
    color: $body-color;
    background-color: transparent;
  }

  .nav-link.active,
  .show > .nav-link {
    color: $body-color;
    background-color: $card-bg;
    box-shadow: $box-shadow-sm;
  }
}

//Navtab custom
.navtab-bg {
  li {
    > a {
      margin: 0 5px;
      background-color: $gray-300;
    }
  }
}

.tab-content {
  padding: 20px 0 0 0;
}
