.automations-page {
    display: flex;
    flex-direction: row;
    .automation-editor {
        flex: 1 1 auto;
        .trigger-section {
            text-align: center;
            .action-block {
                display: block;
            }
            .new-block.has-blocks {
                padding-bottom: 20px;
                &:before {
                    content: " ";
                    width: 2px;
                    height: 32px;
                    background: #9298ad;
                    display: block;
                    position: absolute;
                    bottom: -3px;
                    left: calc(50% - 1px);
                }
                &:after {
                    content: " ";
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    background: #9298ad;
                    border-radius: 50%;
                    left: calc(50% - 4px);
                    bottom: -4px;
                    z-index: 2;
                }
            }
        }
        .block-item {
            position: relative;
            text-align: center;
            padding-top: 20px;
            margin-top: -20px;
            .block-content {
                display: inline-block;
                position: relative;
            }
            .action-block {
                padding-bottom: 20px;
                &:before {
                    height: 25px;
                }
                &:after {
                    content: " ";
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    background: #9298ad;
                    border-radius: 50%;
                    left: calc(50% - 4px);
                    bottom: 17px;
                    z-index: 2;
                }
            }
            .new-block {
                &:before {
                    content: " ";
                    width: 2px;
                    height: 32px;
                    background: #9298ad;
                    display: block;
                    position: absolute;
                    bottom: -3px;
                    left: calc(50% - 1px);
                }
                &:after {
                    bottom: -4px;
                }
            }
            &:hover {
                .block-controls {
                    display: inline-block;
                }
            }
        }
        .block-controls {
            display: none;
            position: absolute;
            border: 1px solid #ced3e0;
            padding: 5px 12px;
            border-radius: 5px;
            z-index: 2;
            right: 40px;
            background: #FFF;
            top: -50px;
            &:before, &:after {
                bottom: -14px;
                left: calc(50% - 6px);
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }
            &:before {
                border-color: rgba(204, 204, 204, 0);
                border-top-color: #ced3e0;
                border-width: 7px;
            }
            &:after {
                border-color: rgba(136, 183, 213, 0);
                border-top-color: #fff;
                border-width: 7px;
                margin-bottom: 1px;
            }
            .btn-action {
                font-size: 18px;
                cursor: pointer;
            }
        }
        .list-blocks {
            .block-item:last-child{
                .action-block:last-child {
                    &:after, &:before {
                        display: none;
                    }
                }
            }
        }
    }
    .automation-actions {
        width: 300px;
    }
    .action-block {
        text-align: center;
        color: #9298ad;
        padding: 0px 40px 30px;
        position: relative;
        &:before {
            content: " ";
            width: 2px;
            height: 35px;
            background: #9298ad;
            display: block;
            position: absolute;
            bottom: -3px;
            left: calc(50% - 1px);
        }
        & + .action-block {
            padding-top: 0px;
            &:before {
                display: none;
            }
        }
        .common-action {
            user-select: none;
            cursor: pointer;
            color: #1f2129;
            border: 1px solid #9298ad;
            background: #fff;
            padding: 12px 12px;
            min-width: 145px;
            display: inline-block;
            font-weight: 700;
            border-radius: 5px;
            &.blue {
                background-color: #1e6cf2;
                border-color: #1265f1;
                color: #fff;
            }
            &.yellow {
                background-color: #e6ce64;
                border-color: #e6ce64;
                color: #fff;
            }
            &.dark-gray {
                background-color: #4e5468;
                border-color: #4e5468;
                color: #fff;
            }
        }
        &:hover {
            .block-controls {
                display: inline-block;
            }
        }
    }
  }