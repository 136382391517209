//
// horizontal-nav.scss
//

body[data-layout='topnav'] {
  .container-fluid {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }

  .navbar-custom {
    position: relative;
    padding: 0;
    .topnav-menu {
      .nav-link {
        &:hover,
        &:focus,
        &:active {
          svg {
            color: $menu-item;
          }
        }
      }
    }
  }

  .content-page {
    padding: 05px 0 5px 0;
    margin-top: 0;
    margin-left: 0 !important;
  }

  .footer {
    left: 0;
  }

  .user-profile {
    padding: 20px 0;
  }

  .profile-dropdown {
    display: block;
  }
}

.topnav {
  background: $bg-topnav;
}

.topbar-nav {
  padding: 0;
  margin: 0;

  .badge {
    display: none;
  }

  .metismenu {
    display: flex;
    flex-direction: column;
    margin: 0;

    .has-arrow {
      &::after {
        right: 1rem;
        width: 0.4em;
        height: 0.4em;
        transform: rotate(45deg) translate(0, -50%);
      }
    }

    .menu-title {
      display: none;
    }

    li {
      &:hover,
      &:focus,
      &:active {
        color: $menu-item-hover;
        text-decoration: none;

        svg {
          color: $menu-item-active;
          fill: rgba($menu-item-active, 0.1);
        }
      }

      a {
        position: relative;
        display: block;
        padding: 1rem;
        font-size: 1rem;
        color: $menu-item;

        i {
          margin-top: -1px;
          vertical-align: middle;
        }

        svg {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin: 0 5px 0 0;
          color: $menu-item;
          fill: rgba($menu-item, 0.12);
        }

        > span {
          vertical-align: middle;
        }

        &:hover,
        &:focus,
        &:active {
          color: $menu-item-hover;
          text-decoration: none;

          svg {
            color: $menu-item-active;
            fill: rgba($menu-item-active, 0.1);
          }
        }
      }

      &:nth-child(2) {
        a {
          padding-left: 0;
        }
      }

      .menu-arrow {
        display: inline-block;
        margin-left: 5px;
        font-family: 'unicons';
        font-size: 1.25rem;
        line-height: 1.3rem;
        vertical-align: middle;
        transition: transform 0.15s;
        transform: translate(0, 0);
        text-rendering: auto;
        &::before {
          content: '\e82d';
        }
      }

      &.mm-active {
        > a {
          color: $menu-item-active;
          svg {
            color: $menu-item-active;
            fill: rgba($menu-item-active, 0.1);
          }

          > span.menu-arrow {
            transform: rotate(180deg);
          }
        }
      }

      &.active {
        > a {
          color: $menu-item-active;
          svg {
            color: $menu-item-active;
            fill: rgba($menu-item-active, 0.1);
          }
        }
      }

      .nav-second-level {
        min-width: calc(#{$dropdown-min-width} + 1.5rem);
        padding: $dropdown-padding-y 0;
        color: $menu-item;
        background-color: $bg-topnav;
        background-clip: padding-box;
        border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
        box-shadow: $dropdown-box-shadow;

        @include font-size(calc(#{$dropdown-font-size} - 0.01rem));

        li {
          &:hover,
          &:focus,
          &:active {
            color: $menu-item-hover;
            text-decoration: none;
          }
          &:first-of-type {
            padding-top: $dropdown-padding-y;
          }

          &:last-of-type {
            padding-bottom: $dropdown-padding-y;
          }
        }

        a {
          display: block;
          width: 100%;
          padding: $dropdown-item-padding-y $dropdown-item-padding-x !important;
          clear: both;
          font-size: $font-size-base;
          font-weight: $font-weight-base;
          line-height: $line-height-base;
          white-space: nowrap;
          background-color: transparent;
          border: 0;

          @include hover-focus {
            color: $dropdown-link-hover-color;
            text-decoration: none;

            @include gradient-bg($dropdown-link-hover-bg);
          }
        }

        .has-arrow {
          &::after {
            margin-top: -2px;
          }
        }
        .menu-arrow {
          float: right;
        }
      }

      .nav-third-level {
        min-width: calc(#{$dropdown-min-width} + 1.5rem);
        color: $menu-item;

        li {
          &:hover,
          &:focus,
          &:active {
            color: $menu-item-hover;
            text-decoration: none;
          }
          &:first-of-type {
            padding-top: $dropdown-padding-y;
          }

          &:last-of-type {
            padding-bottom: $dropdown-padding-y;
          }
        }

        a {
          display: block;
          width: 100%;
          padding: $dropdown-item-padding-y $dropdown-item-padding-x !important;
          padding-left: 2.2rem !important;
          clear: both;
          font-size: $font-size-base;
          font-weight: $font-weight-base;
          line-height: $line-height-base;
          white-space: nowrap;
          background-color: transparent;
          border: 0;

          @include hover-focus {
            color: $dropdown-link-hover-color;
            text-decoration: none;

            @include gradient-bg($dropdown-link-hover-bg);
          }
        }

        .has-arrow {
          &::after {
            margin-top: -2px;
          }
        }
        .menu-arrow {
          float: right;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  body[data-layout='topnav'] {
    .container-fluid {
      max-width: 85%;
    }

    .navbar-custom {
      .logo {
        display: block;
        span {
          &.logo-sm {
            display: none !important;
          }
          &.logo-lg {
            display: block;
          }
        }
      }
    }
    &.left-side-menu-condensed {
      .navbar-custom {
        .button-menu-mobile.disable-btn {
          display: none !important;
        }
      }
    }

    &.boxed-layout {
      .container-fluid {
        max-width: 97%;
      }
    }
  }

  .topbar-nav {
    .metismenu {
      flex-direction: row;

      > li {
        flex-direction: column;

        > ul {
          position: absolute;
          z-index: 1001;
        }
      }

      .side-nav-item {
        &:first-of-type {
          .side-nav-link {
            padding-left: 0;
          }
        }
      }
    }
  }

  .navbar-toggle {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  body[data-layout='topnav'] {
    .navbar-custom {
      .container-fluid {
        padding-right: 12px;
        padding-left: 12px;
      }
    }
  }
  .topnav {
    overflow: auto;
  }
  .topbar-nav {
    max-height: 400px;
    .metismenu {
      li {
        a {
          padding: 15px 0;
        }
        .nav-second-level {
          padding-top: 0;
          box-shadow: none;
          li:first-of-type {
            padding: 0;
          }
          a {
            padding-right: 0 !important;
            &:hover,
            &:focus {
              background-color: transparent;
            }
          }
        }

        .menu-arrow {
          float: right;
          margin-top: 2px;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1180px) {
  .topbar-nav {
    .metismenu {
      li {
        .menu-arrow {
          display: none;
        }
      }
    }
  }
}
