.custom-switch {
  .custom-control-label {
    cursor: pointer;
  }
}

.vti__dropdown {
  &:focus,
  &:active {
    outline: none !important;
  }
}

.coupon-section {
  .sidebar-tab-icon {
    border: 1px solid $primary;
    border-radius: 50%;
  }
  .template-card {
    padding-left: 0 !important;
    overflow: auto !important;
    border-color: inherit !important;
    border-left: none !important;
    -webkit-box-shadow: inherit !important;
    box-shadow: none !important;
  }
  .preview-container {
    .tabs {
      width: 100%;
    }
    .nav-tabs {
      justify-content: center;
      .nav-item {
        a {
          opacity: 0.6;
          &.active {
            background: transparent;
            color: #495057;
            opacity: 1;
          }
        }
      }
    }
    .preview-mockup {
      width: 90%;
      min-height: 600px;
      border: 2px solid #d9dfe6;
      border-radius: 30px;
      position: relative;
      margin: auto;
      margin-bottom: 10px;
      &.mobile {
        width: 375px;
        min-width: initial;
      }
      &:before {
        content: ' ';
        border: 2px solid #d9dfe6;
        position: absolute;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        top: -9px;
        left: -10px;
        border-radius: 36px;
      }
    }
    .print-mockup {
      width: 90%;
      min-height: 600px;
      border-radius: 30px;
      position: relative;
      margin: auto;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .list-devices {
      text-align: center;
      margin-bottom: 20px;
      .device {
        display: inline-block;
        margin: 0px 10px;
        cursor: pointer;
        &.active,
        &:hover {
          i {
            opacity: 1;
          }
        }
        i {
          font-size: 22px;
          color: rgba(0, 14, 36, 0.87);
          opacity: 0.5;
        }
      }
    }
  }
}

.modal-export-image {
  .modal-body {
    background: #ebebeb;
    display: flex;
    justify-content: center;
  }
}

.left-side-menu-condensed .user-profile {
  padding: 10px 20px;
}

.left-side-menu-condensed .left-side-menu {
  height: calc(100vh - #{$topbar-height});
  position: fixed;
  .has-notification & {
    height: calc(100vh - #{$topbar-height} - #{$top-notification-height});
  }
}
.left-side-menu-condensed  .has-notification .left-side-menu {
  height: calc(100vh - #{$topbar-height} - #{$top-notification-height});
}

.flatpickr-current-month .numInputWrapper {
  display: none !important;
}

.custom-switch .custom-control-label::before {
  background-color: #ea4335;
  border-color: #ea4335;
}
.custom-switch .custom-control-label::after {
  background-color: #fff
}

.beta-span {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  color: #1e6cf2;
  letter-spacing: 1px;
}