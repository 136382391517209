//
// flatpickr.scss
//
@import '~flatpickr/dist/flatpickr.css';

.flatpickr-calendar {
  overflow: hidden;
  background-color: $card-bg !important;
  border: 2px solid $gray-300 !important;
  box-shadow: none !important;

  .flatpickr-time {
    border: none !important;

    input,
    .flatpickr-am-pm {
      color: $gray-600;

      &:focus,
      &:hover {
        background: $gray-300;
      }
    }
  }
}

.flatpickr-day {
  color: $gray-600 !important;

  &.selected,
  &.startRange,
  &.endRange,
  &.selected.inRange,
  &.startRange.inRange,
  &.endRange.inRange,
  &.selected:focus,
  &.startRange:focus,
  &.endRange:focus,
  &.selected:hover,
  &.startRange:hover,
  &.endRange:hover,
  &.selected.prevMonthDay,
  &.startRange.prevMonthDay,
  &.endRange.prevMonthDay,
  &.selected.nextMonthDay,
  &.startRange.nextMonthDay,
  &.endRange.nextMonthDay {
    color: $white !important;
    background: $primary !important;
    border-color: $primary !important;
  }

  &.selected.startRange + .endRange:not(:nth-child(7n + 1)),
  &.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
  &.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    box-shadow: -10px 0 0 $primary !important;
  }

  &.today {
    border-color: $gray-300 !important;
  }

  &.inRange {
    background: darken($bg-leftbar-light, 4%) !important;
    border-color: darken($bg-leftbar-light, 4%) !important;
    box-shadow: -10px 0 0 darken($bg-leftbar-light, 4%) !important;

    &.nextMonthDay {
      background: darken($bg-leftbar-light, 4%) !important;
      border-color: darken($bg-leftbar-light, 4%) !important;
    }
  }

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover,
  &.prevMonthDay,
  &.nextMonthDay,
  &.notAllowed,
  &.notAllowed.prevMonthDay,
  &.notAllowed.nextMonthDay {
    color: rgba($gray-600, 0.4);
  }

  &.prevMonthDay.inRange,
  &.nextMonthDay.inRange,
  &.today.inRange,
  &.today:hover,
  &.today:focus,
  &.prevMonthDay.today.inRange,
  &.nextMonthDay.today.inRange,
  &:hover,
  &.prevMonthDay:hover,
  &.nextMonthDay:hover,
  &:focus,
  &.prevMonthDay:focus,
  &.nextMonthDay:focus {
    color: $gray-600;
    background: $gray-300 !important;
    border-color: $gray-300 !important;
  }
}

.flatpickr-time {
  input:hover,
  .flatpickr-am-pm:hover,
  input:focus,
  .flatpickr-am-pm:focus {
    background: $gray-100 !important;
  }
}

.flatpickr-months {
  // background: $primary;
  .flatpickr-month {
    height: 48px !important;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    fill: $body-color !important;
  }
}

.flatpickr-current-month {
  padding: 8px 0 0 0 !important;
  font-size: 1rem !important;
  color: $body-color !important;

  .flatpickr-monthDropdown-months {
    .flatpickr-monthDropdown-month {
      color: $gray-700 !important;
    }
  }
}

.flatpickr-weekdays {
  background: $primary !important;
}

.flatpickr-weekday {
  color: rgba($white, 0.6) !important;
}
