/*
Template Name: Shreyu - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 1.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Custom Bootstrap Css File
*/

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import './node_modules/bootstrap/scss/bootstrap';

//Components
@import 'custom/components/accordions';
@import 'custom/components/alerts';
@import 'custom/components/badge';
@import 'custom/components/backgrounds';
@import 'custom/components/breadcrumb';
@import 'custom/components/buttons';
@import 'custom/components/card';
@import 'custom/components/dropdown';
@import 'custom/components/forms';
@import 'custom/components/modal';
@import 'custom/components/nav';
@import 'custom/components/pagination';
@import 'custom/components/popover';
@import 'custom/components/progress';
@import 'custom/components/reboot';
@import 'custom/components/tables';
@import 'custom/components/type';
@import 'custom/components/notification';

.btn-dark-blue {
  color: #ffffff;
  background-color: #1e3260;
  border-color: #132142;
}

.btn-dark-blue:hover,
.btn-dark-blue:focus,
.btn-dark-blue:active,
.btn-dark-blue.active,
.open .dropdown-toggle.btn-dark-blue {
  color: #ffffff;
  background-color: #2a478c;
  border-color: #1e3260;
}

.btn-dark-blue:active,
.btn-dark-blue.active,
.open .dropdown-toggle.btn-dark-blue {
  background-image: none;
}

.btn-dark-blue.disabled,
.btn-dark-blue[disabled],
fieldset[disabled] .btn-dark-blue,
.btn-dark-blue.disabled:hover,
.btn-dark-blue[disabled]:hover,
fieldset[disabled] .btn-dark-blue:hover,
.btn-dark-blue.disabled:focus,
.btn-dark-blue[disabled]:focus,
fieldset[disabled] .btn-dark-blue:focus,
.btn-dark-blue.disabled:active,
.btn-dark-blue[disabled]:active,
fieldset[disabled] .btn-dark-blue:active,
.btn-dark-blue.disabled.active,
.btn-dark-blue[disabled].active,
fieldset[disabled] .btn-dark-blue.active {
  background-color: #1e3260;
  border-color: #2D2D2D;
  opacity: .6;
}

.btn-default{
  color: #284273;
  background-color: #f2f3f3;
  border-color: #afabab;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #284273;
  background-color: #dddede;
  border-color: #1E345C;
}

.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #284373;
  border-color: #1E345C;
}

.btn-dark-blue .badge {
  color: rgba(40, 67, 115, 0.6);
  background-color: rgba(242, 243, 243, 0.6);
}

.btn-landing{
  background: #fff;
  color: #1E3152;
  font-size: 18px;
  font-weight: bold;
  padding: 4px 12px;
}

.text-dark-blue{
  color: #284373;
}
.text-orange{
  color: #ec792a;
}
#app.zensocial{
  $zen_main: #404040;
  $zen_border: #1a1a1a;
  .gate-left{
    background:$zen_border;
    color:#fff;
  }
  .site-list {
    tr {
      border-bottom: 3px solid $zen_border;
    }
  }
  .auth-container {
    .login-header {
      background:$zen_main;
    }
  }
  .btn-dark-blue, .btn-primary {
    color: #ffffff;
    background-color: $zen_main;
    border-color:$zen_border;
    &.btn-landing{
      background-color: #fff;
      color: $zen_main;
    }
  }

  .btn-dark-blue:hover,
  .btn-dark-blue:focus,
  .btn-dark-blue:active,
  .btn-dark-blue.active,
  .open .dropdown-toggle.btn-dark-blue,
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary.active
  {
    color: #ffffff;
    background-color: $zen_border;
    border-color: $zen_border;
  }
  .btn-dark-blue.disabled,
  .btn-dark-blue[disabled],
  fieldset[disabled] .btn-dark-blue,
  .btn-dark-blue.disabled:hover,
  .btn-dark-blue[disabled]:hover,
  fieldset[disabled] .btn-dark-blue:hover,
  .btn-dark-blue.disabled:focus,
  .btn-dark-blue[disabled]:focus,
  fieldset[disabled] .btn-dark-blue:focus,
  .btn-dark-blue.disabled:active,
  .btn-dark-blue[disabled]:active,
  fieldset[disabled] .btn-dark-blue:active,
  .btn-dark-blue.disabled.active,
  .btn-dark-blue[disabled].active,
  fieldset[disabled] .btn-dark-blue.active {
    background-color: $zen_main;
    border-color: $zen_main;
    opacity: .6;
  }
}
#app.repuagency{
  $new_dark_blue:#2d3648;
  $new_dark_blue_border: #1c212d;
  $side-active:#262626;
  .card-header, .login-header{
    background: $new_dark_blue;
  }
  .gate-left{
    background:$side-active;
    color:#fff;
  }
  .site-list {
    tr {
      border-bottom: 3px solid $side-active;
    }
  }
  .btn-dark-blue, .btn-primary {
    color: #ffffff;
    background-color: $new_dark_blue;
    border-color:$new_dark_blue_border;
  }

  .btn-dark-blue:hover,
  .btn-dark-blue:focus,
  .btn-dark-blue:active,
  .btn-dark-blue.active,
  .open .dropdown-toggle.btn-dark-blue,
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary.active
  {
    color: #ffffff;
    background-color: $new_dark_blue_border;
    border-color: $new_dark_blue_border;
  }
  .btn-dark-blue.disabled,
  .btn-dark-blue[disabled],
  fieldset[disabled] .btn-dark-blue,
  .btn-dark-blue.disabled:hover,
  .btn-dark-blue[disabled]:hover,
  fieldset[disabled] .btn-dark-blue:hover,
  .btn-dark-blue.disabled:focus,
  .btn-dark-blue[disabled]:focus,
  fieldset[disabled] .btn-dark-blue:focus,
  .btn-dark-blue.disabled:active,
  .btn-dark-blue[disabled]:active,
  fieldset[disabled] .btn-dark-blue:active,
  .btn-dark-blue.disabled.active,
  .btn-dark-blue[disabled].active,
  fieldset[disabled] .btn-dark-blue.active {
    background-color: $new_dark_blue;
    border-color: $new_dark_blue;
    opacity: .6;
  }
  .btn-dark-blue .badge {
    color: rgba(40, 67, 115, 0.6);
    background-color: rgba(242, 243, 243, 0.6);
  }

}

td.actions {
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
  word-wrap: break-word;
  min-width: 140px;
  max-width: 140px;
  .btn-delete{
    padding:0 10px;
    color: chocolate;
    font-size: 22px;
    &:hover{
      opacity: .8;
    }
  }
}
.table-responsive{
  td{
    vertical-align: middle;
  }
}
.btn-action{
  background: transparent !important;
  border: 0 !important;
  font-size: 30px;
  padding: 0;
  &:hover{
    opacity: .8;
  }
  &:focus{
    box-shadow: none !important;
  }
  span[class*="spinner"]{
    vertical-align: baseline;
    width: 25px;
    height: 25px;
  }
}
