//
// preloader.scss
//

.preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: $body-bg;
}

.status {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 80px;
  margin: -40px 0 0 -40px;
}

.spinner {
  > div {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 7px;
    background: $white;
    border: 2px solid $primary;
    border-radius: 50%;
    animation: circle-loader 1s infinite ease-in-out both;
  }
  .circle1 {
    animation-delay: -0.32s;
  }
  .circle2 {
    animation-delay: -0.16s;
  }
}

@keyframes circle-loader {
  0%,
  80%,
  100% {
    transform: scale(0.4);
  }
  40% {
    transform: scale(1);
  }
}
