//
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    margin: 0 3px;
    border: none;
    border-radius: 30px !important;
  }
}
.page-item {
    &.active .page-link {
    color: #fff;
    background-color: #1e3260;
    border-color: #142242;
  }
}
