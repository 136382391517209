//
// card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  border-radius: 0.25rem;
  box-shadow: $box-shadow-sm;
}

.card-drop {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: -6px;
  font-size: 20px;
  line-height: 30px;
  color: inherit;
  text-align: center;
  border-radius: 50%;
  &:hover {
    color: $dark;
    background-color: $gray-200;
  }
}

// Card title / Card Header
.card-title,
.card-header {
  margin-top: 0;
}

.card-nav {
  margin: -0.5rem 0;
}

// Custom card box
.card-box {
  padding: $card-spacer-x;
  margin-bottom: $grid-gutter-width;
  background-color: $card-bg;
  box-shadow: $box-shadow-sm;

  @include border-radius($card-border-radius);
}

.header-title {
  font-size: 1.04rem;
  font-weight: 700;
}

.sub-header {
  margin-bottom: $grid-gutter-width;
  color: $gray-600;
}
