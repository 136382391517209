//
// page-title.scss
//

.page-title-topbar {
  margin: 0;
  font-size: 20px;
  line-height: $topbar-height;
}

.page-title {
  padding: 20px 0;
  .breadcrumb {
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
  }

  h4 {
    font-size: 19px;
  }
}
